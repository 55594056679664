import "./App.css";
import EntryScreenHandler from "./utils/EntryScreenHandler";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

function App() {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
        firebase.firestore().settings({ experimentalForceLongPolling: true });
        console.log("NODE_ENV: " + process.env.NODE_ENV);
        if (process.env.NODE_ENV === "production") {
            firebase.analytics();
        }
    }
    return <EntryScreenHandler />;
}

export default App;
