import { Center, Button, Box, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";

export default function NotFound404Screen() {
    const history = useHistory();

    return (
        <>
            <Helmet>
                <title>404</title>
            </Helmet>
            <Center flexDir="column" gridGap={10}>
                <Box maxW={1150} w="80%" textAlign="center">
                    <Text as="h2" fontSize="4xl">
                        Page Not Found
                    </Text>
                </Box>
                <Button onClick={() => history.push("/")}>
                    Return to Home
                </Button>
            </Center>
        </>
    );
}
