import { extendTheme, ColorModeOptions } from "@chakra-ui/react";

const colors = {
    primary: {
        100: "#E5FCF1",
        200: "#27EF96",
        300: "#10DE82",
        400: "#0EBE6F",
        500: "#0CA25F",
        600: "#0A864F",
        700: "#086F42",
        800: "#075C37",
        900: "#064C2E",
    },
};

const styles = {
    // global: {
    //   body: {
    //     bg: "blackAlpha.800",
    //     color: "white"
    //   }
    // }
};

const config: ColorModeOptions = {
    initialColorMode: "light",
    // useSystemColorMode: true
};

const customTheme = extendTheme({ colors, styles, config });

export default customTheme;
