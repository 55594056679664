import { ReactNode } from "react";
import Logo from "./Logo";
import {
    Box,
    Container,
    Link,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue,
    HStack,
    Image,
} from "@chakra-ui/react";
import ANIcon from "../img/an-logo-blue.png";

const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
            {children}
        </Text>
    );
};

export default function Footer() {
    return (
        <Box
            bg={useColorModeValue("gray.50", "gray.900")}
            color={useColorModeValue("gray.700", "gray.200")}
        >
            <Container as={Stack} maxW={"6xl"} py={10}>
                <SimpleGrid
                    templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr" }}
                    spacing={8}
                >
                    <Stack spacing={6}>
                        <Link href="/">
                            <Box>
                                <Logo
                                    color={useColorModeValue(
                                        "gray.700",
                                        "white"
                                    )}
                                />
                            </Box>
                        </Link>
                        <Text fontSize={"sm"}>
                            © Aniruth Narayanan. All rights reserved.
                        </Text>
                        <Link
                            w="min-content"
                            href={"https://aniruthn.com"}
                            target="_blank"
                            rel="noopener noreferrer"
                            fontSize={"sm"}
                        >
                            <HStack>
                                <Image src={ANIcon} h="35px" />
                                <Text fontSize={"sm"}>aniruthn.com</Text>
                            </HStack>
                        </Link>
                    </Stack>
                    <Stack align={"flex-start"}>
                        <ListHeader>Product</ListHeader>
                        <Link href={"/features"}>Features</Link>
                        <Link href={"/about"}>About</Link>
                        <Link href={"/features#releases"}>Releases</Link>
                    </Stack>
                    <Stack align={"flex-start"}>
                        <ListHeader>Support</ListHeader>
                        <Link href={"/about#faq"}>FAQ</Link>
                        <Link href={"/tos"}>Terms of Service</Link>
                        <Link href={"/privacy"}>Privacy Policy</Link>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    );
}
