import {
    Box,
    Button,
    Center,
    CloseButton,
    Icon,
    Flex,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Spacer,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useRef, useMemo, useState } from "react";
import { RiSendPlane2Line } from "react-icons/ri";
import { BsChatSquareDots, BsChatSquareDotsFill } from "react-icons/bs";
import MessageModel from "../models/message";
import ReactMarkdown from "react-markdown";

interface ChatWidgetPropTypes {
    messages: MessageModel[];
    handleNewUserMessage: (msg: string) => void;
    displayName: string | null | undefined;
}

export default function ChatWidget({
    messages,
    handleNewUserMessage,
    displayName,
}: ChatWidgetPropTypes) {
    console.log(displayName);
    const selfName =
        displayName !== null && displayName !== undefined ? displayName : "You";
    console.log(selfName);
    const ChatMessages = () => (
        <>
            {messages.map((message, index) => (
                <ChatMessage
                    key={index}
                    displayMessage={message}
                    selfName={selfName}
                />
            ))}
        </>
    );
    const [chatWindowShown, setChatWindowShown] = useState(false);

    const scrollDownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        scrollDownRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages, chatWindowShown]);

    const [inputMessage, setInputMessage] = useState("");

    const handleInputMessageAddition = () => {
        if (inputMessage !== "") {
            handleNewUserMessage(inputMessage);
            setInputMessage("");
        }
    };

    useEffect(() => {
        console.log(inputMessage);
    }, [inputMessage]);

    var Filter = require("bad-words"),
        filter = new Filter({ placeholder: "" });

    const chatIcon = useColorModeValue(
        <BsChatSquareDots />,
        <BsChatSquareDotsFill />
    );

    const bg = useColorModeValue("white", "gray.800");

    return (
        <Box pos="fixed" bottom="24px" right="24px" zIndex={1} w="min-content">
            {chatWindowShown && (
                <Box
                    bg={bg}
                    border="1px solid"
                    borderColor="inherit"
                    borderRadius="var(--chakra-radii-md)"
                    maxW="sm"
                >
                    <Text
                        textAlign="center"
                        border="1px solid"
                        borderColor="inherit"
                        borderRadius="var(--chakra-radii-md)"
                        fontSize="xl"
                        p={2}
                    >
                        Chat
                    </Text>
                    <Flex
                        flexDir="column"
                        maxH="md"
                        overflowY="auto"
                        minH="200px"
                    >
                        <ChatMessages />
                        <Box ref={scrollDownRef}></Box>
                    </Flex>
                    <InputGroup w="xs" size="md">
                        <Input
                            placeholder="Message"
                            maxLength={50}
                            pr="4rem"
                            value={inputMessage}
                            onChange={(e) =>
                                setInputMessage(
                                    // e.target.value !== null &&
                                    //     e.target.value !== undefined &&
                                    //     e.target.value !== ""
                                    //     ? filter
                                    //           .clean(e.target.value)
                                    //           .replace(
                                    //               /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                                    //               ""
                                    //           )
                                    //     :
                                    e.target.value
                                )
                            }
                            onKeyPress={(kp) =>
                                kp.key === "Enter"
                                    ? handleInputMessageAddition()
                                    : null
                            }
                        />
                        <InputRightElement width="4rem">
                            <Button
                                h="1.75rem"
                                size="sm"
                                onClick={handleInputMessageAddition}
                            >
                                <Icon as={RiSendPlane2Line} color="green" />
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </Box>
            )}
            <HStack mt={4}>
                <Spacer />
                <button onClick={() => setChatWindowShown(!chatWindowShown)}>
                    <Center
                        w="40px"
                        h="40px"
                        bg={useColorModeValue("primary.700", "primary.400")}
                        color="white"
                    >
                        {chatWindowShown ? <CloseButton /> : chatIcon}
                    </Center>
                </button>
            </HStack>
        </Box>
    );
}

const ChatMessage = ({ displayMessage, selfName }: any) => {
    const message = displayMessage as MessageModel;
    const bg = useColorModeValue("primary.400", "primary.700");
    const MessageToReturn = useMemo(
        () => () => {
            const marginLeft = message.author === "self" ? "auto" : 0;
            const marginRight = message.author !== "self" ? "auto" : 0;
            const align = message.author === "self" ? "right" : "left";
            return (
                <Box ml={marginLeft} mr={marginRight}>
                    <Box
                        textAlign={align}
                        p={2}
                        my={2}
                        borderRadius={15}
                        mx={2}
                        bg={message.author === "self" ? bg : "inherit"}
                    >
                        <Text>
                            {message.author === "self"
                                ? selfName
                                : message.author}
                        </Text>
                        <Text wordBreak="break-word">
                            <ReactMarkdown
                                children={message.message}
                                skipHtml
                            />
                        </Text>
                        <Text>{message.timestamp.toLocaleTimeString()}</Text>
                    </Box>
                </Box>
            );
        },
        [message]
    );
    return <MessageToReturn />;
};
