import { Box, Center, Text, Flex, Link } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

export default function TOSScreen() {
    return (
        <>
            <Helmet>
                <title>Terms of Service</title>
            </Helmet>
            <Center flexDir="column" gridGap={10} mb={10}>
                <Box maxW={1150} w="80%" textAlign="center">
                    <Text as="h2" fontSize="4xl">
                        Terms of Service
                    </Text>
                </Box>
                <Flex flexDir="column" maxW={1150} w="80%" gridGap={6}>
                    <Text>
                        Please note: what follows is a draft of the terms of
                        service, which is not final, but is still binding.
                    </Text>
                    <Text>
                        By using this website, you agree to the following terms
                        and conditions, including the privacy policy.
                    </Text>
                    <Box>
                        <Link href="/privacy">Privacy Policy</Link>
                    </Box>
                    <Text>
                        All services are provided as is. TouchBase is not liable
                        for any device damage, inaccuracies, viruses, damage,
                        etc. By using TouchBase, you agree to indemnify both
                        Aniruth Narayanan and TouchBase.
                    </Text>
                    <Text>
                        In accordance with regulation, you must be at least 13
                        years old.
                    </Text>
                    <Text>
                        You may not upload any viruses or code, nor can you
                        access or collect any data that you do not have
                        permision to access.
                    </Text>
                </Flex>
            </Center>
        </>
    );
}
