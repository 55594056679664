import { Center, Button, Text, Avatar } from "@chakra-ui/react";
import firebase from "firebase/app";
import "firebase/auth";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";

export default function AccountScreen() {
    const user = firebase.auth().currentUser;
    const email = user?.email;
    const name = user?.displayName;
    const photoURL = user?.photoURL;
    const history = useHistory();

    //TODO: add ability to add friends, handle requests somehow

    const UserAvatar = () => {
        if (photoURL) {
            return <Avatar size="2xl" src={photoURL} alt="Profile Picture" />;
        } else {
            return <></>;
        }
    };

    return (
        <>
            <Helmet>
                <title>Account</title>
            </Helmet>
            <Center flexDir="column" gridGap={10}>
                <Text>{name}'s Account</Text>
                <UserAvatar />
                <Text>Email: {email}</Text>
                <Button onClick={() => history.push("/room")}>
                    Enter a Room
                </Button>
            </Center>
        </>
    );
}
