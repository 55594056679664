import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import customTheme from "./utils/theme";
import { Helmet } from "react-helmet";

const browserHistory = createBrowserHistory();

const LocalDevTools = () =>
    process.env.NODE_ENV === "development" ? (
        <Helmet>
            <script src="http://localhost:8097"></script>
        </Helmet>
    ) : (
        <></>
    );

ReactDOM.render(
    <React.StrictMode>
        <LocalDevTools />
        <ChakraProvider theme={customTheme}>
            <ColorModeScript
                initialColorMode={customTheme.config.initialColorMode}
            />
            <Router history={browserHistory}>
                <App />
            </Router>
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
