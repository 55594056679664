import firebase from "firebase/app";
import "firebase/auth";
import { useEffect, useState } from "react";
import { Spinner, Center, Flex, Box } from "@chakra-ui/react";
import SignInScreen from "../screens/SignInScreen";
import SignUpScreen from "../screens/SignUpScreen";
import RoomScreen from "../screens/RoomScreen";
import JoinRoomScreen from "../screens/JoinRoom";
import HomeScreen from "../screens/HomeScreen";
import AccountScreen from "../screens/AccountScreen";
import { Route, Switch } from "react-router";
import NavBar from "../components/NavBar";
import FeaturesScreen from "../screens/FeaturesScreen";
import AboutScreen from "../screens/AboutScreen";
import Footer from "../components/Footer";
import PrivacyScreen from "../screens/PrivacyScreen";
import TOSScreen from "../screens/TOSScreen";
import NotFound404Screen from "../screens/NotFound404Screen";

export default function EntryScreenHandler() {
    const [initializing, setInitializing] = useState(true);
    const [user, setUser] = useState<firebase.User | null>(null);
    let userAsBoolean: boolean = user ? true : false;
    useEffect(() => {
        const unsubscribe = firebase
            .auth()
            .onAuthStateChanged((currentUser: firebase.User | null) => {
                setUser(currentUser);
                if (initializing) setInitializing(false);
            });
        return unsubscribe;
    }, [setUser]);

    const GeneralRoutes = [
        <Route exact path="/features" component={FeaturesScreen} />,
        <Route exact path="/about" component={AboutScreen} />,
        <Route exact path="/privacy" component={PrivacyScreen} />,
        <Route exact path="/tos" component={TOSScreen} />,
        <Route component={NotFound404Screen} />,
    ];

    const SignedInRoutes = [
        <Route exact path="/" component={AccountScreen} />,
        <Route exact path="/overview" component={HomeScreen} />,
        <Route exact path="/room" component={JoinRoomScreen} />,
        <Route exact path="/room/:roomCode" component={RoomScreen} />,
    ];

    const SignedOutRoutes = [
        <Route exact path="/" component={HomeScreen} />,
        <Route exact path="/signin" component={SignInScreen} />,
        <Route exact path="/signup" component={SignUpScreen} />,
    ];

    let routes: JSX.Element[] = [];

    if (userAsBoolean) {
        routes = SignedInRoutes.concat(GeneralRoutes);
    } else {
        routes = SignedOutRoutes.concat(GeneralRoutes);
    }

    if (initializing) {
        return (
            <Center w="100vw" h="100vh">
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                />
            </Center>
        );
    } else {
        return (
            <>
                <Flex flexDir="column" minH="100%">
                    <Box flex={0}>
                        {/* @ts-ignore */}
                        <NavBar user={userAsBoolean} />
                    </Box>
                    <Box flex={1}>
                        <Switch>{routes}</Switch>
                    </Box>
                    <Box flex={0}>
                        <Footer />
                    </Box>
                </Flex>
            </>
        );
    }
}
