import { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { useHistory } from "react-router";
import {
    Center,
    Text,
    InputGroup,
    InputRightElement,
    IconButton,
    Button,
    Input,
    useDisclosure,
    HStack,
    VStack,
    useToast,
} from "@chakra-ui/react";
import AlertModal from "../components/AlertModal";
import { HiEye, HiEyeOff } from "react-icons/hi";
import GoogleButton from "../components/GoogleButton";
import { Helmet } from "react-helmet";

export default function SignInScreen() {
    const auth = firebase.auth();
    const GoogleAuth = new firebase.auth.GoogleAuthProvider();
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [show, setShow] = useState(false);

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");

    const { isOpen, onOpen, onClose } = useDisclosure();

    const trySignIn = async () => {
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password);
            toast({
                title: "Sign In Successful",
                description: "Your account has been verified.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            history.push("/");
        } catch (error: any) {
            console.error(error);
            setTitle("Sign In Unsuccessful");
            setMessage(error.message);
            onOpen();
        }
    };

    const resetPassword = async () => {
        try {
            await firebase.auth().sendPasswordResetEmail(email);
            setTitle("Email Sent");
            setMessage(
                "Please check your email for information on how to reset your password."
            );
            onOpen();
        } catch (error: any) {
            console.error(error);
            setTitle("Unable To Reset Password");
            setMessage(error.message);
            onOpen();
        }
    };

    const toast = useToast();

    return (
        <>
            <Helmet>
                <title>Sign In</title>
            </Helmet>
            <AlertModal
                title={title}
                message={message}
                isOpen={isOpen}
                onClose={onClose}
            />
            <Center flexDirection="column" gridGap={10}>
                <Text fontSize="2xl">Sign In</Text>
                <GoogleButton
                    handler={() => {
                        auth.signInWithPopup(GoogleAuth)
                            .then(() => {
                                toast({
                                    title: "Sign In Successful",
                                    description:
                                        "Your account has been verified.",
                                    status: "success",
                                    duration: 3000,
                                    isClosable: true,
                                });
                                history.push("/");
                            })
                            .catch((err) => console.error(err));
                    }}
                />
                <VStack>
                    <Text>Email</Text>
                    <Input
                        maxW="sm"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Text>Password</Text>
                    <InputGroup maxW="sm" size="md">
                        <Input
                            pr="4.5rem"
                            type={show ? "text" : "password"}
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputRightElement>
                            <IconButton
                                variant="ghost"
                                bg="transparent !important"
                                onClick={() => setShow(!show)}
                                aria-label={
                                    show ? "Mask password" : "Reveal password"
                                }
                                icon={show ? <HiEyeOff /> : <HiEye />}
                            ></IconButton>
                        </InputRightElement>
                    </InputGroup>
                    <HStack>
                        <Button size="sm" onClick={trySignIn}>
                            Sign In
                        </Button>
                        <Button size="sm" onClick={resetPassword}>
                            Reset Password
                        </Button>
                    </HStack>
                </VStack>
                <Text w="80%">
                    By logging in to TouchBase, a video application, you attest
                    that you are over 13 years of age.
                </Text>
                <Text w="80%">Google sign-in is recommended.</Text>
            </Center>
        </>
    );
}
