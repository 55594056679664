import {
    HStack,
    List,
    ListIcon,
    ListItem,
    Spacer,
    Text,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";

interface ReleaseNotePropType {
    title: string;
    date: string;
    notes: string[];
}

export default function ReleaseNote({
    title,
    date,
    notes,
}: ReleaseNotePropType) {
    const Notes = () => (
        <>
            {notes.map((note: string, index: number) => (
                <ListItem key={index}>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    {note}
                </ListItem>
            ))}
        </>
    );

    return (
        <List spacing={3}>
            <HStack>
                <Text as="h4" fontSize="xl">
                    {title}
                </Text>
                <Spacer />
                <Text as="h4" fontSize="xl">
                    {date}
                </Text>
            </HStack>
            <Notes />
        </List>
    );
}
