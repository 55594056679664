import { Box, Text, Flex, Stack, Image } from "@chakra-ui/react";

interface FeatureProps {
    title: string;
    subtitle: string;
    image: string;
    side: "left" | "right";
}

export default function Feature({
    title,
    subtitle,
    image,
    side,
}: FeatureProps) {
    return (
        <Box maxW={1150}>
            <Flex
                flexFlow="row wrap"
                m="auto"
                justifyContent="center"
                gridGap={5}
                direction={
                    side === "left"
                        ? { base: "column", md: "row" }
                        : { base: "column-reverse", md: "row-reverse" }
                }
            >
                <Stack
                    spacing={4}
                    w={{ base: "80%", md: "40%" }}
                    align={["center", "center", "flex-start", "flex-start"]}
                >
                    <Text as="h3" fontSize="2xl">
                        {title}
                    </Text>
                    <Text>{subtitle}</Text>
                </Stack>
                <Box
                    w={{ base: "80%", sm: "60%", md: "50%" }}
                    mb={{ base: 12, md: 0 }}
                >
                    <Image
                        src={image}
                        size="100%"
                        rounded="1rem"
                        shadow="2xl"
                    />
                </Box>
            </Flex>
        </Box>
    );
}
