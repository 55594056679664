import {
    Box,
    Center,
    UnorderedList,
    ListItem,
    Text,
    Flex,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";

export default function PrivacyScreen() {
    return (
        <>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <Center flexDir="column" gridGap={10} mb={10}>
                <Box maxW={1150} w="80%" textAlign="center">
                    <Text as="h2" fontSize="4xl">
                        Privacy Policy
                    </Text>
                </Box>
                <Flex flexDir="column" maxW={1150} w="80%" gridGap={6}>
                    <Text>
                        Please note: what follows is a draft of the privacy
                        policy, which is not final, but is still binding.
                    </Text>
                    <Text>
                        Here is the personal information that is collected from
                        you:
                    </Text>
                    <UnorderedList spacing={3}>
                        <ListItem>
                            Your name and email associated with your account
                        </ListItem>
                        <ListItem>
                            Which rooms you are a part of and who is in those
                            rooms
                        </ListItem>
                        <ListItem>
                            Information necessary for others to know how to
                            connect with your computer (your computer would need
                            to opt in by accepting, which is automated through
                            TouchBase)
                        </ListItem>
                        <ListItem>
                            Google Analytics tracks your usage of the website
                            and your IP address
                        </ListItem>
                    </UnorderedList>
                    <Text>
                        Please note that the technologies used may have privacy
                        policies of their own, such as Google
                        Firebase/Firestore/Analytics/Authentication.
                    </Text>
                    <Text>
                        Your video and audio feeds are <i>not</i> tracked or
                        stored. Computers communicate peer-to-peer over SSL
                        encryption using WebRTC technology; it is not routed
                        through any central server. Chat messages are also not
                        tracked or stored for the same reason. This project uses
                        STUN servers to connect computers but does not utilize
                        TURN servers to send data through.
                    </Text>
                    <Text>
                        Efforts are taken to abide by privacy regulations.
                        Please contact me if there is something missing.
                    </Text>
                </Flex>
            </Center>
        </>
    );
}
