import { Center } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import HeroComponent from "../components/Hero";
import tableImage from "../img/table-with-four-people.png";

export default function HomeScreen() {
    return (
        <>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <Center flexDir="column">
                <HeroComponent
                    image={tableImage}
                    title="Keep Connections Continuing"
                    subtitle="Set up a recurring meeting today!"
                    ctaText="Sign up now"
                    ctaLink="/signup"
                    maxW={1400}
                />
            </Center>
        </>
    );
}
