import { Box, Image, Text, Tooltip } from "@chakra-ui/react";

interface AwardPropTypes {
    id: string;
    icon_url: string;
    count: number;
    name: string;
    description: string;
    [x: string]: any;
}

export default function Award({ award }: any) {
    const typedAward = award as AwardPropTypes;
    const name = new DOMParser().parseFromString(typedAward.name, "text/html")
        .documentElement.textContent;
    const description = new DOMParser().parseFromString(
        typedAward.description,
        "text/html"
    ).documentElement.textContent;
    const awardInfo = () => {
        return (
            <Box>
                <Text fontSize="s">
                    {name}, {typedAward.count}
                </Text>
                <Text fontSize="xs">{description}</Text>
            </Box>
        );
    };
    // TODO: change tooltips to popovers only for awards
    return (
        <Tooltip hasArrow label={awardInfo()} placement="auto">
            <Image src={typedAward.icon_url} height={3} m={1} />
        </Tooltip>
    );
}
