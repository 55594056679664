import { AspectRatio } from "@chakra-ui/react";
import { VideoHTMLAttributes, useEffect, useRef } from "react";

type PropsType = VideoHTMLAttributes<HTMLVideoElement> & {
    srcObject: MediaStream | null;
    mute: boolean;
    aspectRatio: number;
};

export default function Video({
    srcObject,
    mute,
    aspectRatio,
    ...props
}: PropsType) {
    const refVideo = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (!refVideo.current) return;
        refVideo.current.srcObject = srcObject;
    }, [srcObject]);

    // TODO: add mute button based on the srcObject!.getAudioTracks()[0].enabled property

    return (
        <AspectRatio ratio={aspectRatio}>
            <video muted={mute} ref={refVideo} {...props} />
        </AspectRatio>
    );
}
