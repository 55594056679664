//@ts-nocheck
import { useState } from "react";
import {
    Link,
    Box,
    Flex,
    Text,
    Button,
    Stack,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";
import firebase from "firebase/app";
import "firebase/auth";
import { IoSunnyOutline, IoMoonSharp } from "react-icons/io5";

import Logo from "./Logo";

export default function NavBar({ user, props }) {
    const [isOpen, setIsOpen] = useState(false);
    let userAsBoolean: boolean = user ? true : false;

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            mb={8}
            p={8}
            // bg={["primary.500", "primary.500", "transparent", "transparent"]}
            bg="transparent"
            // color={useColorModeValue(
            //     ["white", "white", "primary.700", "primary.700"],
            //     ["white", "white", "primary.400", "primary.400"]
            // )}
            color={useColorModeValue("primary.700", "primary.400")}
            {...props}
        >
            <Link href={"/"}>
                <Logo
                    // color={["white", "white", "primary.500", "primary.500"]}
                    color="primary.500"
                />
            </Link>
            <MenuToggle toggle={toggle} isOpen={isOpen} />
            <MenuLinks userAsBoolean={userAsBoolean} isOpen={isOpen} />
        </Flex>
    );
}

const LightCloseIcon = () => (
    <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <title>Close</title>
        <path
            fill="white"
            d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
        />
    </svg>
);

const LightMenuIcon = () => (
    <svg
        width="24px"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        fill="white"
    >
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
);

const DarkCloseIcon = () => (
    <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <title>Close</title>
        <path
            fill="black"
            d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
        />
    </svg>
);

const DarkMenuIcon = () => (
    <svg
        width="24px"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        fill="black"
    >
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
);

const CloseIcon = () => {
    return useColorModeValue(<DarkCloseIcon />, <LightCloseIcon />);
};

const MenuIcon = () => {
    return useColorModeValue(<DarkMenuIcon />, <LightMenuIcon />);
};

const MenuToggle = ({ toggle, isOpen }) => {
    return (
        <Box display={{ base: "block", md: "none" }} onClick={toggle}>
            {isOpen ? <CloseIcon /> : <MenuIcon />}
        </Box>
    );
};

const MenuItem = ({ children, to = "/", ...rest }) => {
    return (
        <Link href={to}>
            <Text display="block" {...rest}>
                {children}
            </Text>
        </Link>
    );
};

const MenuLinks = ({ userAsBoolean, isOpen }) => {
    const { colorMode, toggleColorMode } = useColorMode();
    return (
        <Box
            display={{ base: isOpen ? "block" : "none", md: "block" }}
            flexBasis={{ base: "100%", md: "auto" }}
        >
            <Stack
                spacing={8}
                align="center"
                justify={["center", "space-between", "flex-end", "flex-end"]}
                direction={["column", "row", "row", "row"]}
                pt={[4, 4, 0, 0]}
            >
                <MenuItem to="/">Home</MenuItem>
                <MenuItem to="/features">Features</MenuItem>
                <MenuItem to="/about">About</MenuItem>
                <Button size="sm" maxW="xs" onClick={toggleColorMode}>
                    {colorMode === "light" ? (
                        <IoMoonSharp />
                    ) : (
                        <IoSunnyOutline />
                    )}
                </Button>
                {userAsBoolean ? <SignedInMenuItems /> : <SignedOutMenuItems />}
            </Stack>
        </Box>
    );
};

const SignedInMenuItems = () => {
    return (
        <MenuItem to="/">
            <Button
                size="sm"
                rounded="md"
                color={["primary.500", "primary.500", "white", "white"]}
                bg={["white", "white", "primary.500", "primary.500"]}
                _hover={{
                    bg: [
                        "primary.100",
                        "primary.100",
                        "primary.600",
                        "primary.600",
                    ],
                }}
                onClick={() => firebase.auth().signOut()}
            >
                Sign Out
            </Button>
        </MenuItem>
    );
};

const SignedOutMenuItems = () => {
    return (
        <>
            <MenuItem to="/signin">
                <Button
                    size="sm"
                    rounded="md"
                    color={["white", "white", "primary.500", "primary.500"]}
                    bg={["primary.500", "primary.500", "white", "white"]}
                    _hover={{
                        bg: [
                            "primary.600",
                            "primary.600",
                            "primary.100",
                            "primary.100",
                        ],
                    }}
                >
                    Sign In
                </Button>
            </MenuItem>
            <MenuItem to="/signup">
                <Button
                    size="sm"
                    rounded="md"
                    color={["primary.500", "primary.500", "white", "white"]}
                    bg={["white", "white", "primary.500", "primary.500"]}
                    _hover={{
                        bg: [
                            "primary.100",
                            "primary.100",
                            "primary.600",
                            "primary.600",
                        ],
                    }}
                >
                    Sign Up
                </Button>
            </MenuItem>
        </>
    );
};
