import { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { useHistory } from "react-router";
import {
    Center,
    Text,
    IconButton,
    InputGroup,
    InputRightElement,
    Button,
    Input,
    VStack,
    useToast,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import GoogleButton from "../components/GoogleButton";
import { Helmet } from "react-helmet";

export default function SignUpScreen() {
    const auth = firebase.auth();
    const GoogleAuth = new firebase.auth.GoogleAuthProvider();
    const history = useHistory();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [show, setShow] = useState(false);

    const createAccount = async () => {
        await auth
            .createUserWithEmailAndPassword(email, password)
            .catch((err: any) => console.error(err));
        await auth?.currentUser
            ?.updateProfile({ displayName: name })
            .catch((err: any) => console.error(err));
    };

    const toast = useToast();

    return (
        <>
            <Helmet>
                <title>Sign Up</title>
            </Helmet>
            <Center flexDirection="column" gridGap={10}>
                <Text fontSize="2xl">Sign Up</Text>
                <GoogleButton
                    handler={() => {
                        auth.signInWithPopup(GoogleAuth)
                            .then(() => {
                                toast({
                                    title: "Sign Up Successful",
                                    description:
                                        "Google Account has been verified.",
                                    status: "success",
                                    duration: 3000,
                                    isClosable: true,
                                });
                                history.push("/");
                            })
                            .catch((err) => console.error(err));
                    }}
                />
                <VStack>
                    <Text>Name</Text>
                    <Input
                        maxW="sm"
                        placeholder="Enter name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Text>Email</Text>
                    <Input
                        maxW="sm"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Text>Password</Text>
                    <InputGroup maxW="sm" size="md">
                        <Input
                            pr="4.5rem"
                            type={show ? "text" : "password"}
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputRightElement>
                            <IconButton
                                variant="ghost"
                                bg="transparent !important"
                                onClick={() => setShow(!show)}
                                aria-label={
                                    show ? "Mask password" : "Reveal password"
                                }
                                icon={show ? <HiEyeOff /> : <HiEye />}
                            ></IconButton>
                        </InputRightElement>
                    </InputGroup>
                    <Button
                        size="sm"
                        onClick={() =>
                            createAccount()
                                .then(() => {
                                    toast({
                                        title: "Sign Up Successful",
                                        description:
                                            "Your account has been verified.",
                                        status: "success",
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                    history.push("/");
                                })
                                .catch((err) => console.error(err))
                        }
                    >
                        Create Account
                    </Button>
                </VStack>
                <Text w="80%">
                    By logging in to TouchBase, a video application, you attest
                    that you are over 13 years of age.
                </Text>
                <Text w="80%">Google sign-in is recommended.</Text>
            </Center>
        </>
    );
}
