import { useState } from "react";
import {
    Button,
    Center,
    Flex,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightElement,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { IoIosReturnLeft } from "react-icons/io";
import Post from "./Post";
import { useMemo } from "react";
import AlertModal from "./AlertModal";

export default function RedditFeed() {
    const [subreddit, setSubreddit] = useState("");
    const [limit, setLimit] = useState("10");
    const [posts, setPosts] = useState([]);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");

    const getSubredditPosts = async (numTries: number) => {
        if (subreddit === "") {
            setTitle("Subreddit Error");
            setMessage("The subreddit name is empty.");
            return onOpen();
        } else if (limit === "" || Number(limit) > 20 || Number(limit) < 1) {
            setTitle("Limit Error");
            setMessage("The limit is not between 1 and 20.");
            return onOpen();
        }
        const url =
            "https://www.reddit.com/r/" +
            subreddit +
            "/top.json?limit=" +
            limit;
        try {
            const resp = await axios.get(url, { responseType: "json" });
            const posts = resp.data.data.children.map((post: any) => post.data);
            setPosts(posts);
        } catch (err) {
            console.log(err);
            if (err.message === "Network Error" && numTries === 0) {
                getSubredditPosts(1);
            }
            setTitle("Subreddit Error");
            setMessage("Unfortunately, that subreddit cannot be accessed.");
            return onOpen();
        }
    };

    const DisplayPosts = useMemo(
        () => () => {
            return (
                <Flex
                    flexFlow="row wrap"
                    maxW={620}
                    m="auto"
                    justifyContent="center"
                    h={500}
                    overflow="auto"
                    mt={10}
                >
                    {posts.map((post: any) => (
                        <Post post={post} />
                    ))}
                </Flex>
            );
        },
        [posts]
    );

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <AlertModal
                title={title}
                message={message}
                isOpen={isOpen}
                onClose={onClose}
            />
            <Flex flexDirection="column">
                <Center flexDirection="column">
                    <Text>Subreddit Name</Text>
                    <InputGroup maxW="xs" size="md">
                        <InputLeftAddon children="r/" />
                        <Input
                            placeholder="Subreddit"
                            value={subreddit}
                            onChange={(e) => setSubreddit(e.target.value)}
                            onKeyPress={(kp) =>
                                kp.key === "Enter" ? getSubredditPosts(0) : null
                            }
                            pr="4rem"
                        />
                        <InputRightElement width="4rem">
                            <Button
                                h="1.75rem"
                                size="sm"
                                onClick={() => getSubredditPosts(0)}
                            >
                                <IoIosReturnLeft color="green" />
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <Text>Number of Posts (Max 20)</Text>
                    <Input
                        placeholder="Limit"
                        maxW="xs"
                        value={limit}
                        onChange={(e) =>
                            Number(e.target.value) <= 20
                                ? setLimit(e.target.value)
                                : null
                        }
                    />
                    <DisplayPosts />
                </Center>
            </Flex>
        </>
    );
}
