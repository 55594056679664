import { Accordion, Box, Center, Text, Stack, Link } from "@chakra-ui/react";
import AccordionSection from "../components/AccordionSection";
import { Helmet } from "react-helmet";
import HashLinkObserver from "react-hash-link";

export default function AboutScreen() {
    return (
        <>
            <HashLinkObserver />
            <Helmet>
                <title>About TouchBase</title>
            </Helmet>
            <Center flexDir="column" gridGap={10} mb={10}>
                <Box maxW={1150} w="80%" textAlign="center">
                    <Text as="h2" fontSize="4xl">
                        The age of digital communication is upon us
                    </Text>
                    <Text>
                        TouchBase helps you maintain the connections of past
                        friends.
                    </Text>
                </Box>
                <Stack maxW={1150} w="80%" spacing={4}>
                    <Text>
                        It's common to say that you will touch base with someone
                        or a group of friends in the future. But things become
                        busy, and you find yourself occupied.
                    </Text>
                    <Text>
                        More importantly, what would you even talk about?
                    </Text>
                    <Text>
                        TouchBase aims to recreate the spontaneity of group
                        hangouts, with a seemingly never ending stream of
                        discussion topics directly integrated into a secure
                        video platform.
                    </Text>
                    <Text>
                        {" "}
                        Learn more about TouchBase by reading the design
                        document about its architecture and features here: {""}
                        <Link
                            href={
                                process.env.PUBLIC_URL +
                                "/assets/design-document-touchbase.pdf"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Design Document
                        </Link>
                    </Text>
                </Stack>
                <Box maxW={1150} w="80%" id="faq">
                    <Text textAlign="center" as="h3" fontSize="2xl" mb={10}>
                        FAQ
                    </Text>
                    <Accordion allowToggle>
                        <AccordionSection
                            question="What is the security of TouchBase?"
                            answer="TouchBase is highly secure. WebRTC technology developed by Apple, Google, Microsoft, Mozilla, and Opera is being used which is SSL encrypted to send your video and audio feeds to the other users on the platform. Additionally, Google Firebase is used for authentication with your Google Account, ensuring that your information remains private. For more information, read the privacy policy and terms of service."
                        />
                        <AccordionSection
                            question="Why is my video and audio not connecting within the room, but other video applications work?"
                            answer="Since TouchBase is entirely peer-to-peer, some public networks may block such traffic. The video and audio feeds themselves are not routed through a central server, which may lead to such an issue."
                        />
                        <AccordionSection
                            question="Why is Reddit not working sometimes?"
                            answer="TouchBase uses the Reddit API to query information that is used to display posts. This sometimes may not work due to server issues or to network incompatability. It is recommended that you retry after waiting to see if it works. Additionally, some subreddits may be private or inaccessible, but most (i.e. r/all) should work."
                        />
                    </Accordion>
                </Box>
            </Center>
        </>
    );
}
