import { useState } from "react";
import "./JoinRoomScreenStyles.css";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import {
    Button,
    Input,
    Text,
    useDisclosure,
    VStack,
    InputRightElement,
    InputGroup,
    InputLeftAddon,
    Textarea,
    Select,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useColorMode,
} from "@chakra-ui/react";
import { useHistory } from "react-router";
import AlertModal from "../components/AlertModal";
import { Helmet } from "react-helmet";
import { IoIosReturnLeft } from "react-icons/io";
import { CalendarOptions, GoogleCalendar } from "datebook";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";

export default function JoinRoomScreen() {
    const firestore = firebase.firestore();
    const history = useHistory();
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");

    const [joinRoomCode, setJoinRoomCode] = useState("");

    const [eventTitle, setEventTitle] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [eventFrequency, setEventFrequency] = useState("WEEKLY");
    const [eventInterval, setEventInterval] = useState(1);
    const [eventStartDate, setEventStartDate] = useState<Date>();
    const [eventEndDate, setEventEndDate] = useState<Date>();

    const createRoom = async () => {
        const room = firestore.collection("rooms").doc();
        await room
            .set({
                numPeople: 0,
                uid1: firebase.auth().currentUser?.uid,
            })
            .catch((err) => console.error(err));
        return room.id;
    };

    const createAndJoinRoom = async () => {
        const roomId = await createRoom();
        history.push("/room/" + roomId);
    };

    const joinRoom = async () => {
        if (joinRoomCode.length !== 20) {
            setMessage("Unfortunately, that room code is invalid.");
            return onOpen();
        }
        const roomCodeDoc = firestore.collection("rooms").doc(joinRoomCode);
        const result = await roomCodeDoc.get();
        if (!result.exists) {
            setTitle("Room Code Error");
            setMessage("Unfortunately, that room does not exist.");
            return onOpen();
        }
        history.push("/room/" + joinRoomCode);
    };

    const { isOpen, onOpen, onClose } = useDisclosure();

    const parseDate = (str: string, format: string, locale: any) => {
        const parsed = dateFnsParse(str, format, new Date(), { locale });
        if (DateUtils.isDate(parsed)) {
            return parsed;
        }
        return undefined;
    };

    const formatDate = (date: Date, format: string, locale: any) => {
        return dateFnsFormat(date, format, { locale });
    };

    const FORMAT = "MM/dd/yyyy";

    const { colorMode } = useColorMode();
    return (
        <>
            <Helmet>
                <title>Join Room</title>
            </Helmet>
            <AlertModal
                title={title}
                message={message}
                isOpen={isOpen}
                onClose={onClose}
            />
            <VStack
                gridGap={10}
                mb={10}
                w={["80%", "80%", "100%", "100%"]}
                mx="auto"
            >
                <Text as="h3" fontSize="2xl">
                    Create or Join a Room
                </Text>
                <Button onClick={createAndJoinRoom} colorScheme="blue">
                    Create Room
                </Button>
                <InputGroup maxW="xs" size="md">
                    <InputLeftAddon children="Join:" />
                    <Input
                        placeholder="Room Code"
                        pr="4rem"
                        maxLength={20}
                        value={joinRoomCode}
                        onChange={(e) => setJoinRoomCode(e.target.value)}
                        onKeyPress={(kp) =>
                            kp.key === "Enter" ? joinRoom() : null
                        }
                    />
                    <InputRightElement width="4rem">
                        <Button
                            h="1.75rem"
                            size="sm"
                            onClick={() => joinRoom()}
                        >
                            <IoIosReturnLeft color="green" />
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <Text as="h3" fontSize="2xl">
                    Schedule a Recurring Event
                </Text>
                <VStack gridGap={2} w="100%">
                    <Text>Title</Text>
                    <Input
                        maxW="xs"
                        size="md"
                        placeholder="Event Title"
                        value={eventTitle}
                        onChange={(e) => setEventTitle(e.target.value)}
                    />
                    <Text>Description</Text>
                    <Textarea
                        placeholder="Event Description"
                        maxW="xs"
                        size="md"
                        value={eventDescription}
                        onChange={(e) => setEventDescription(e.target.value)}
                    />
                    <Text>Frequency</Text>
                    <Select
                        maxW="xs"
                        size="md"
                        onChange={(e) => setEventFrequency(e.target.value)}
                        value={eventFrequency}
                    >
                        <option value="DAILY">Daily</option>
                        <option value="WEEKLY">Weekly</option>
                        <option value="MONTHLY">Monthly</option>
                        <option value="YEARLY">Daily</option>
                    </Select>
                    <Text>Interval</Text>
                    <NumberInput
                        allowMouseWheel
                        maxW="xs"
                        size="md"
                        step={1}
                        defaultValue={1}
                        min={1}
                        value={eventInterval}
                        onChange={(e) => setEventInterval(Number(e))}
                    >
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                    <Text>Start Day</Text>
                    {!eventStartDate && (
                        <Text fontSize="sm">Choose a start day</Text>
                    )}
                    {colorMode === "dark" ? (
                        <DayPickerInput
                            onDayChange={setEventStartDate}
                            formatDate={formatDate}
                            format={FORMAT}
                            parseDate={parseDate}
                            placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
                            inputProps={{
                                style: {
                                    backgroundColor:
                                        "var(--chakra-colors-gray-800)",
                                    color: "var(--chakra-colors-whiteAlpha-900)",
                                },
                            }}
                            style={{
                                color: "black",
                            }}
                        />
                    ) : (
                        <DayPickerInput
                            onDayChange={setEventStartDate}
                            formatDate={formatDate}
                            format={FORMAT}
                            parseDate={parseDate}
                            placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
                        />
                    )}
                    <Text>End Date</Text>
                    {!eventEndDate && (
                        <Text fontSize="sm">Choose an end day</Text>
                    )}
                    {colorMode === "dark" ? (
                        <DayPickerInput
                            onDayChange={setEventEndDate}
                            formatDate={formatDate}
                            format={FORMAT}
                            parseDate={parseDate}
                            placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
                            inputProps={{
                                style: {
                                    backgroundColor:
                                        "var(--chakra-colors-gray-800)",
                                    color: "var(--chakra-colors-whiteAlpha-900)",
                                },
                            }}
                            style={{
                                color: "black",
                            }}
                        />
                    ) : (
                        <DayPickerInput
                            onDayChange={setEventEndDate}
                            formatDate={formatDate}
                            format={FORMAT}
                            parseDate={parseDate}
                            placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
                        />
                    )}
                </VStack>
                <Button
                    colorScheme="red"
                    onClick={async () => {
                        const roomId = await createRoom();
                        if (eventStartDate === undefined) return;
                        const options: CalendarOptions = {
                            location:
                                "https://tbase.aniruthn.com/room/" + roomId,
                            title: eventTitle,
                            description: eventDescription,
                            start: eventStartDate,
                            end: eventEndDate,
                            recurrence: {
                                frequency: eventFrequency,
                                interval: eventInterval,
                            },
                        };
                        const email = firebase.auth().currentUser?.email;
                        if (email === undefined) return; //avoids TypeScript error
                        const url = new GoogleCalendar(options).render();
                        window.open(url, "_blank", "noopener noreferrer");
                    }}
                >
                    Schedule Event
                </Button>
            </VStack>
        </>
    );
}
