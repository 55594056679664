import {
    Box,
    Center,
    Flex,
    HStack,
    Icon,
    Image,
    Link,
    Text,
    Tooltip,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { HiOutlineExternalLink } from "react-icons/hi";
import { BsArrowUpDown } from "react-icons/bs";
import { AiOutlinePercentage, AiOutlineTrophy } from "react-icons/ai";
import "./PostStyles.css";
import { useMemo } from "react";
import Award from "./Award";
import PostModel from "./../models/post";

export default function Post({ post }: any) {
    const typedPost = post as PostModel;
    const title = new DOMParser().parseFromString(typedPost.title, "text/html")
        .documentElement.textContent;
    const Awards = useMemo(
        () => () => {
            return (
                <Flex flexFlow="row wrap" justifyContent="center">
                    {post.all_awardings.map((award: any) => (
                        <Award award={award} />
                    ))}
                </Flex>
            );
        },
        [post.all_awardings]
    );
    const Thumbnail = useMemo(
        () => () => {
            if (
                typedPost.thumbnail !== "nsfw" &&
                typedPost.thumbnail !== "self" &&
                typedPost.thumbnail !== "image"
            ) {
                return (
                    <Image
                        src={typedPost.thumbnail}
                        height={typedPost.thumbnail_height}
                    />
                );
            } else {
                return <></>;
            }
        },
        [typedPost.thumbnail]
    );
    const date = new Date(typedPost.created_utc * 1000);
    return (
        <Box
            key={typedPost.permalink}
            flexBasis={300}
            padding={2}
            flexGrow={1}
            maxW={300}
        >
            <Center flexDir="column" padding={4} className="box">
                <Link
                    href={"https://reddit.com" + typedPost.permalink}
                    isExternal
                    rel="noopener noreferrer"
                    color={useColorModeValue("GrayText", "gray.300")}
                    textAlign="center"
                >
                    {title}
                    <Icon as={HiOutlineExternalLink} mx="2px" />
                </Link>
                <Box textAlign="left" w="100%" py={2}>
                    <Awards />
                    <Text fontSize="sm">
                        Posted by:{" "}
                        <Link
                            href={"https://reddit.com/user" + typedPost.author}
                            isExternal
                            rel="noopener noreferrer"
                            color={useColorModeValue("GrayText", "gray.400")}
                            textAlign="center"
                        >
                            {typedPost.author}
                        </Link>
                    </Text>
                    <Text fontSize="sm">
                        <Link
                            href={
                                "https://reddit.com" +
                                typedPost.subreddit_name_prefixed
                            }
                            isExternal
                            rel="noopener noreferrer"
                            color={useColorModeValue("GrayText", "gray.400")}
                            textAlign="center"
                        >
                            {typedPost.subreddit_name_prefixed}
                        </Link>
                    </Text>
                    <Text fontSize="sm">{date.toLocaleString()}</Text>
                </Box>
                <Thumbnail />
                <Box py={2}>
                    <HStack justifyContent="space-between" flex={1}>
                        <Tooltip label="Awards" fontSize="xs">
                            <VStack spacing={0}>
                                <Icon as={AiOutlineTrophy} />

                                <Text fontSize="xs">
                                    {typedPost.total_awards_received}
                                </Text>
                            </VStack>
                        </Tooltip>
                        <Tooltip label="Score" fontSize="xs">
                            <VStack spacing={0}>
                                <Icon as={BsArrowUpDown} />

                                <Text fontSize="xs">
                                    {typedPost.score
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Text>
                            </VStack>
                        </Tooltip>
                        <Tooltip label="Upvote Ratio" fontSize="xs">
                            <VStack spacing={0}>
                                <Icon as={AiOutlinePercentage} />
                                <Text fontSize="xs">
                                    {typedPost.upvote_ratio}
                                </Text>
                            </VStack>
                        </Tooltip>
                    </HStack>
                </Box>
            </Center>
        </Box>
    );
}
