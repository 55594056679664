import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
} from "@chakra-ui/react";

interface AccordionSectionProps {
    question: string;
    answer: string;
}

export default function AccordionSection({
    question,
    answer,
}: AccordionSectionProps) {
    return (
        <AccordionItem>
            <h2>
                <AccordionButton>
                    <Box flex="1" textAlign="left">
                        {question}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>{answer}</AccordionPanel>
        </AccordionItem>
    );
}
