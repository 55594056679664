import { useEffect, useState, useRef } from "react";
import "./RoomScreenStyles.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Video from "../components/Video";
import { useHistory, useParams } from "react-router";
import RoomModel from "./../models/room";
import {
    Box,
    Button,
    Center,
    Grid,
    GridItem,
    HStack,
    Text,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
import { AiOutlineAudioMuted, AiOutlineAudio } from "react-icons/ai";
import { BiVideoOff, BiVideo } from "react-icons/bi";
import { FcReddit, FcBrokenLink } from "react-icons/fc";
import RedditFeed from "../components/RedditFeed";
import { Helmet } from "react-helmet";
import { copyToClipboard } from "../utils/copyLogic";
import ChatWidget from "../components/ChatWidget";
import MessageModel from "../models/message";

// TODO: move all connection logic to a utils file and only return the relevant video feeds info

const servers = {
    iceServers: [
        {
            urls: [
                "stun:stun1.l.google.com:19302",
                "stun:stun2.l.google.com:19302",
            ],
        },
    ],
    iceCandidatePoolSize: 20,
};

let c1 = new RTCPeerConnection(servers);
let c2 = new RTCPeerConnection(servers);
let c3 = new RTCPeerConnection(servers);
let numUser: number;
let c1ChatChannel: RTCDataChannel;
let c2ChatChannel: RTCDataChannel;
let c3ChatChannel: RTCDataChannel;

//TODO: add filter to clean all messages

export default function RoomScreen() {
    const { roomCode } = useParams<{ roomCode: string }>();
    const history = useHistory();
    const firestore = firebase.firestore();

    const messagesRef = useRef<MessageModel[]>([]);

    const [messages, setMessages] = useState<MessageModel[]>([]);
    const addMessage = (msg: MessageModel) => {
        const oldMessages = messagesRef.current;
        const newMessages = [...oldMessages, msg];
        console.log(newMessages);
        setMessages(newMessages);
        messagesRef.current = newMessages;
    };

    c1.oniceconnectionstatechange = async () => {
        if (c1.iceConnectionState === "disconnected") {
            console.log("c1 disconnected");
            resetFeed(1);
        }
    };
    c2.oniceconnectionstatechange = async () => {
        if (c2.iceConnectionState === "disconnected") {
            console.log("c2 disconnected");
            resetFeed(2);
        }
    };
    c3.oniceconnectionstatechange = async () => {
        if (c3.iceConnectionState === "disconnected") {
            console.log("c3 disconnected");
            resetFeed(3);
        }
    };

    const resetFeed = async (feedNum: number) => {
        let disconnectedUser: number;

        if (feedNum === 1 && numUser !== 1) {
            disconnectedUser = 1;
        } else if (
            (feedNum === 2 && (numUser === 3 || numUser === 4)) ||
            (feedNum === 1 && numUser === 1)
        ) {
            disconnectedUser = 2;
        } else if (
            (feedNum === 2 && (numUser === 1 || numUser === 2)) ||
            numUser === 4
        ) {
            disconnectedUser = 3;
        } else {
            disconnectedUser = 4;
        }

        if (
            disconnectedUser === 1 ||
            (disconnectedUser === 2 && numUser === 1)
        ) {
            c1 = c2;
            c2 = c3;
            setC1Local(c2Local);
            setC2Local(c3Local);
            setC1Remote(c2Remote);
            setC2Remote(c3Remote);
        } else if (
            (disconnectedUser === 2 && (numUser === 3 || numUser === 4)) ||
            (disconnectedUser === 3 && (numUser === 1 || numUser === 2))
        ) {
            c2 = c3;
            setC2Local(c3Local);
            setC2Remote(c3Remote);
        }

        c3 = new RTCPeerConnection(servers);
        let newC3Local = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
        });
        newC3Local.getTracks().forEach((track) => {
            c3.addTrack(track, newC3Local);
        });

        setC3Local(newC3Local);
        let newC3Remote = new MediaStream();
        c3.ontrack = (event) => {
            event.streams[0].getTracks().forEach((track) => {
                newC3Remote.addTrack(track);
            });
        };
        setC3Remote(newC3Remote);
        c3.oniceconnectionstatechange = async () => {
            if (c3.iceConnectionState === "disconnected") {
                console.log("c3 disconnected");
                resetFeed(3);
            }
        };
        c3ChatChannel = c3.createDataChannel("chat");
        c3ChatChannel.onopen = () => {
            console.log("c3 chat opened");
        };
        c3.ondatachannel = (event) => {
            const c3ReceivingChannel = event.channel;
            c3ReceivingChannel.onmessage = (event) => {
                const data = JSON.parse(event.data);
                const messageToAdd: MessageModel = {
                    author: data.author,
                    message: data.message,
                    timestamp: new Date(data.timestamp as string),
                };
                addMessage(messageToAdd);
            };
        };

        console.log("reset" + feedNum);
        const newFeed = await offerFeed(3);
        const roomCodeDoc = firestore.collection("rooms").doc(roomCode);
        console.log("starting set up");
        const result = await roomCodeDoc.get();
        const room = result.data() as RoomModel;
        const numPeople = room.numPeople;

        console.log(numUser);
        console.log(disconnectedUser);

        if (numUser === 1) {
            const newFeeds = room.feeds1!;
            if (disconnectedUser === 2) {
                roomCodeDoc.update({
                    numPeople: numPeople - 1,
                    feeds1: [newFeeds[1], newFeeds[2], newFeed],
                });
            } else if (disconnectedUser === 3) {
                roomCodeDoc.update({
                    numPeople: numPeople - 1,
                    feeds1: [newFeeds[0], newFeeds[2], newFeed],
                });
            } else {
                roomCodeDoc.update({
                    numPeople: numPeople - 1,
                    feeds1: [newFeeds[0], newFeeds[1], newFeed],
                });
            }
        } else if (numUser === 2) {
            const newFeeds = room.feeds2!;
            if (disconnectedUser === 1) {
                roomCodeDoc.update({
                    numPeople: numPeople - 1,
                    feeds1: [newFeeds[1], newFeeds[2], newFeed],
                    uid1: firebase.auth().currentUser?.uid,
                });
                numUser = 1;
            } else if (disconnectedUser === 3) {
                roomCodeDoc.update({
                    feeds2: [newFeeds[0], newFeeds[2], newFeed],
                });
            } else {
                roomCodeDoc.update({
                    feeds2: [newFeeds[0], newFeeds[1], newFeed],
                });
            }
        } else if (numUser === 3) {
            const newFeeds = room.feeds3!;
            if (disconnectedUser === 1) {
                roomCodeDoc.update({
                    feeds2: [newFeeds[1], newFeeds[2], newFeed],
                    uid2: firebase.auth().currentUser?.uid,
                });
                numUser = 2;
            } else if (disconnectedUser === 2) {
                roomCodeDoc.update({
                    feeds2: [newFeeds[0], newFeeds[2], newFeed],
                    uid2: firebase.auth().currentUser?.uid,
                });
                numUser = 2;
            } else {
                roomCodeDoc.update({
                    feeds3: [newFeeds[0], newFeeds[1], newFeed],
                });
            }
        } else {
            const newFeeds = room.feeds4!;
            numUser = 3;
            if (disconnectedUser === 1) {
                roomCodeDoc.update({
                    feeds3: [newFeeds[1], newFeeds[2], newFeed],
                    uid3: firebase.auth().currentUser?.uid,
                });
            } else if (disconnectedUser === 2) {
                roomCodeDoc.update({
                    feeds3: [newFeeds[0], newFeeds[2], newFeed],
                    uid3: firebase.auth().currentUser?.uid,
                });
            } else {
                roomCodeDoc.update({
                    feeds3: [newFeeds[0], newFeeds[1], newFeed],
                    uid3: firebase.auth().currentUser?.uid,
                });
            }
        }
    };

    useEffect(() => {
        initialSetUp();
    }, []);

    const [c1Local, setC1Local] = useState<MediaStream | null>(null);
    const [c2Local, setC2Local] = useState<MediaStream | null>(null);
    const [c3Local, setC3Local] = useState<MediaStream | null>(null);
    const [c1Remote, setC1Remote] = useState<MediaStream | null>(null);
    const [c2Remote, setC2Remote] = useState<MediaStream | null>(null);
    const [c3Remote, setC3Remote] = useState<MediaStream | null>(null);

    const initialSetUp = async () => {
        let newC1Local = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
        });
        let newC2Local = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
        });
        let newC3Local = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
        });

        let newC1Remote = new MediaStream();
        let newC2Remote = new MediaStream();
        let newC3Remote = new MediaStream();

        newC1Local.getTracks().forEach((track) => {
            c1.addTrack(track, newC1Local);
        });
        newC2Local.getTracks().forEach((track) => {
            c2.addTrack(track, newC2Local);
        });
        newC3Local.getTracks().forEach((track) => {
            c3.addTrack(track, newC3Local);
        });

        c1.ontrack = (event) => {
            event.streams[0].getTracks().forEach((track) => {
                newC1Remote.addTrack(track);
            });
        };
        c2.ontrack = (event) => {
            event.streams[0].getTracks().forEach((track) => {
                newC2Remote.addTrack(track);
            });
        };
        c3.ontrack = (event) => {
            event.streams[0].getTracks().forEach((track) => {
                newC3Remote.addTrack(track);
            });
        };

        setC1Local(newC1Local);
        setC2Local(newC2Local);
        setC3Local(newC3Local);

        setC1Remote(newC1Remote);
        setC2Remote(newC2Remote);
        setC3Remote(newC3Remote);

        c1ChatChannel = c1.createDataChannel("chat");
        c1ChatChannel.onopen = () => {
            console.log("c1 chat opened");
        };
        c1.ondatachannel = (event) => {
            const c1ReceivingChannel = event.channel;
            c1ReceivingChannel.onmessage = (event) => {
                const data = JSON.parse(event.data);
                const messageToAdd: MessageModel = {
                    author: data.author,
                    message: data.message,
                    timestamp: new Date(data.timestamp as string),
                };
                addMessage(messageToAdd);
            };
        };
        c2ChatChannel = c2.createDataChannel("chat");
        c2ChatChannel.onopen = () => {
            console.log("c2 chat opened");
        };
        c2.ondatachannel = (event) => {
            const c2ReceivingChannel = event.channel;
            c2ReceivingChannel.onmessage = (event) => {
                const data = JSON.parse(event.data);
                const messageToAdd: MessageModel = {
                    author: data.author,
                    message: data.message,
                    timestamp: new Date(data.timestamp as string),
                };
                addMessage(messageToAdd);
            };
        };
        c3ChatChannel = c3.createDataChannel("chat");
        c3ChatChannel.onopen = () => {
            console.log("c3 chat opened");
        };
        c3.ondatachannel = (event) => {
            const c3ReceivingChannel = event.channel;
            c3ReceivingChannel.onmessage = (event) => {
                const data = JSON.parse(event.data);
                const messageToAdd: MessageModel = {
                    author: data.author,
                    message: data.message,
                    timestamp: new Date(data.timestamp as string),
                };
                addMessage(messageToAdd);
            };
        };

        const roomCodeDoc = firestore.collection("rooms").doc(roomCode);
        console.log("starting set up");
        const result = await roomCodeDoc.get();
        const room = result.data() as RoomModel;
        console.log("got room");
        const numPeople = room.numPeople;
        if (numPeople === 0) {
            numUser = 1;
            const [feed12, feed13, feed14] = await Promise.all([
                offerFeed(1),
                offerFeed(2),
                offerFeed(3),
            ]);
            roomCodeDoc.update({
                numPeople: numPeople + 1,
                feeds1: [feed12, feed13, feed14],
            });
        } else if (numPeople === 1) {
            numUser = 2;
            answerFeed(room.feeds1![0], 1);
            const [feed23, feed24] = await Promise.all([
                offerFeed(2),
                offerFeed(3),
            ]);
            roomCodeDoc.update({
                numPeople: numPeople + 1,
                uid2: firebase.auth().currentUser?.uid,
                feeds2: [room.feeds1![0], feed23, feed24],
            });
        } else if (numPeople === 2) {
            numUser = 3;
            answerFeed(room.feeds1![1], 1);
            answerFeed(room.feeds2![1], 2);
            const feed34: string = await offerFeed(3);
            roomCodeDoc.update({
                numPeople: numPeople + 1,
                uid3: firebase.auth().currentUser?.uid,
                feeds3: [room.feeds1![1], room.feeds2![1], feed34],
            });
        } else if (numPeople === 3) {
            numUser = 4;
            answerFeed(room.feeds1![2], 1);
            answerFeed(room.feeds2![2], 2);
            answerFeed(room.feeds3![2], 3);
            roomCodeDoc.update({
                numPeople: numPeople + 1,
                uid4: firebase.auth().currentUser?.uid,
                feeds4: [room.feeds1![2], room.feeds2![2], room.feeds3![2]],
            });
        } else {
            history.push("/room");
        }
        console.log(numUser);
    };

    const offerFeed = async (feedNum: number) => {
        const callDoc = firestore.collection("calls").doc();
        const offerCandidates = callDoc.collection("offerCandidates");
        const answerCandidates = callDoc.collection("answerCandidates");

        console.log("accessed collections doc");

        let feed: RTCPeerConnection = c1;
        if (feedNum === 2) {
            feed = c2;
        } else if (feedNum === 3) {
            feed = c3;
        }

        // Get candidates for caller, save to db
        feed.onicecandidate = (event) => {
            console.log(event);
            event.candidate && offerCandidates.add(event.candidate.toJSON());
        };

        // Create offer
        const offerDescription = await feed.createOffer();
        await feed.setLocalDescription(offerDescription);

        console.log("offer description" + feedNum);
        console.log(offerDescription);

        const offer = {
            sdp: offerDescription.sdp,
            type: offerDescription.type,
        };

        await callDoc.set({ offer });

        console.log("offer" + feedNum);

        // Listen for remote answer
        callDoc.onSnapshot((snapshot) => {
            const data = snapshot.data();
            if (!feed.currentRemoteDescription && data?.answer) {
                const answerDescription = new RTCSessionDescription(
                    data.answer
                );
                feed.setRemoteDescription(answerDescription);
            }
        });

        // When answered, add candidate to peer connection
        answerCandidates.onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    const candidate = new RTCIceCandidate(change.doc.data());
                    feed.addIceCandidate(candidate);
                }
            });
        });
        console.log("call doc made" + feedNum);
        return callDoc.id;
    };

    const answerFeed = async (feedId: string, feedNum: number) => {
        const callDoc = firestore.collection("calls").doc(feedId);
        const answerCandidates = callDoc.collection("answerCandidates");
        const offerCandidates = callDoc.collection("offerCandidates");

        console.log("accessed answers doc" + feedNum);

        let feed: RTCPeerConnection = c1;
        if (feedNum === 2) {
            feed = c2;
        } else if (feedNum === 3) {
            feed = c3;
        }

        feed.onicecandidate = (event) => {
            event.candidate && answerCandidates.add(event.candidate.toJSON());
        };

        const callData = (await callDoc.get()).data();

        const offerDescription = callData?.offer;
        await feed.setRemoteDescription(
            new RTCSessionDescription(offerDescription)
        );

        const answerDescription = await feed.createAnswer();
        await feed.setLocalDescription(answerDescription);

        console.log("answer description" + feedNum);

        const answer = {
            type: answerDescription.type,
            sdp: answerDescription.sdp,
        };

        await callDoc.update({ answer });

        console.log("got answer" + feedNum);

        offerCandidates.onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    let data = change.doc.data();
                    feed!.addIceCandidate(new RTCIceCandidate(data));
                }
                console.log(feed.connectionState);
            });
        });
    };

    useEffect(() => {
        if (process.env.NODE_ENV !== "development") {
            window.addEventListener("beforeunload", alertUser);
            return () => window.removeEventListener("beforeunload", alertUser);
        }
    }, []);

    const alertUser = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = "";
    };

    const [personalFeedAudio, setPersonalFeedAudio] = useState(true);
    const [personalFeedVideo, setPersonalFeedVideo] = useState(true);

    const muteHandler = () => {
        c1Local!.getAudioTracks()[0].enabled =
            !c1Local?.getAudioTracks()[0].enabled;
        c2Local!.getAudioTracks()[0].enabled =
            !c2Local?.getAudioTracks()[0].enabled;
        c3Local!.getAudioTracks()[0].enabled =
            !c3Local?.getAudioTracks()[0].enabled;
        setPersonalFeedAudio(!personalFeedAudio);
    };

    const videoHandler = () => {
        c1Local!.getVideoTracks()[0].enabled =
            !c1Local?.getVideoTracks()[0].enabled;
        c2Local!.getVideoTracks()[0].enabled =
            !c2Local?.getVideoTracks()[0].enabled;
        c3Local!.getVideoTracks()[0].enabled =
            !c3Local?.getVideoTracks()[0].enabled;
        setPersonalFeedVideo(!personalFeedVideo);
    };

    const buttonColor = useColorModeValue("primary.700", "primary.400");

    const PersonalFeedMuteButton = () => {
        return (
            <button onClick={muteHandler}>
                <Center w="40px" h="40px" bg={buttonColor} color="white">
                    {personalFeedAudio ? (
                        <AiOutlineAudio />
                    ) : (
                        <AiOutlineAudioMuted />
                    )}
                </Center>
            </button>
        );
    };

    const PersonalFeedVideoButton = () => {
        return (
            <button onClick={videoHandler}>
                <Center w="40px" h="40px" bg={buttonColor} color="white">
                    {personalFeedVideo ? <BiVideo /> : <BiVideoOff />}
                </Center>
            </button>
        );
    };

    const [redditFeedEnabled, setRedditFeedEnabled] = useState(true);

    const EnableRedditFeedButton = () => {
        return (
            <button onClick={redditHandler}>
                <Center w="40px" h="40px" bg={buttonColor} color="white">
                    {redditFeedEnabled ? <FcReddit /> : <FcBrokenLink />}
                </Center>
            </button>
        );
    };

    const redditHandler = () => {
        setRedditFeedEnabled(!redditFeedEnabled);
        !redditFeedEnabled ? setAspectRatio(1.25) : setAspectRatio(16 / 9);
    };

    // TODO: Add slider to custom change aspect ratio

    const [aspectRatio, setAspectRatio] = useState(1.25);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.altKey && event.key === "m") {
                muteHandler();
            } else if (event.altKey && event.key === "v") {
                videoHandler();
            } else if (event.altKey && event.key === "r") {
                redditHandler();
            } else if (event.altKey && event.key === "c") {
                copyToClipboard("https://tbase.aniruthn.com/room/" + roomCode);
            }
        };
        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);
    });

    const toast = useToast();

    const handleNewUserMessage = (msg: string) => {
        const time = new Date();
        const message = {
            author: firebase.auth().currentUser!.displayName!,
            message: msg,
            timestamp: time,
        };
        if (c1ChatChannel !== undefined && c1ChatChannel.readyState === "open")
            c1ChatChannel.send(JSON.stringify(message));
        if (c2ChatChannel !== undefined && c2ChatChannel.readyState === "open")
            c2ChatChannel.send(JSON.stringify(message));
        if (c3ChatChannel !== undefined && c3ChatChannel.readyState === "open")
            c3ChatChannel.send(JSON.stringify(message));
        const selfMessage: MessageModel = {
            author: "self",
            message: msg,
            timestamp: time,
        };
        addMessage(selfMessage);
    };

    return (
        <>
            <Helmet>
                <title>Room {roomCode}</title>
            </Helmet>
            <ChatWidget
                messages={messages}
                handleNewUserMessage={handleNewUserMessage}
                displayName={firebase.auth().currentUser?.displayName}
            />
            <Box maxW="1600px" ml="auto" mr="auto">
                <Center flexDir="row" gridGap={4} mb={10} mx="auto">
                    <Text>Room Code: {roomCode}</Text>
                    <Button
                        size="sm"
                        onClick={() => {
                            copyToClipboard(
                                "https://tbase.aniruthn.com/room/" + roomCode
                            );
                            toast({
                                title: "Room Link Copied",
                                description:
                                    "https://tbase.aniruthn.com/room/" +
                                    roomCode,
                                status: "success",
                                duration: 3000,
                                isClosable: true,
                            });
                        }}
                    >
                        Copy Room Link
                    </Button>
                    <Button
                        size="sm"
                        onClick={() => {
                            copyToClipboard(roomCode);
                            toast({
                                title: "Room Code Copied",
                                description: "Room " + roomCode,
                                status: "success",
                                duration: 3000,
                                isClosable: true,
                            });
                        }}
                    >
                        Copy Room Code
                    </Button>
                </Center>
                <Center h="100%">
                    <Grid
                        templateRows="repeat(2, 1fr)"
                        templateColumns={
                            redditFeedEnabled
                                ? "minmax(100px, 1fr) minmax(200px, 1.5fr) minmax(100px, 1fr)"
                                : "repeat(2, minmax(100px, 500px))"
                        }
                        gap={4}
                        h="100%"
                        w={redditFeedEnabled ? "100%" : "auto"}
                    >
                        <GridItem rowSpan={1} colSpan={1} maxW={475}>
                            {/* TODO: Add option to unmirror personal video feed */}
                            <Video
                                autoPlay
                                playsInline
                                srcObject={c1Local}
                                mute={true}
                                aspectRatio={aspectRatio}
                                style={{ transform: "scaleX(-1)" }}
                            ></Video>
                            <Center>
                                <HStack spacing="20px">
                                    <PersonalFeedMuteButton />
                                    <PersonalFeedVideoButton />
                                    <EnableRedditFeedButton />
                                </HStack>
                            </Center>
                        </GridItem>
                        {redditFeedEnabled ? (
                            <GridItem rowSpan={2} colSpan={1}>
                                <RedditFeed />
                            </GridItem>
                        ) : (
                            <></>
                        )}
                        <GridItem rowSpan={1} colSpan={1} maxW={475}>
                            <Video
                                autoPlay
                                playsInline
                                srcObject={c1Remote}
                                mute={false}
                                aspectRatio={aspectRatio}
                            ></Video>
                        </GridItem>
                        <GridItem rowSpan={1} colSpan={1} maxW={475}>
                            <Video
                                autoPlay
                                playsInline
                                srcObject={c2Remote}
                                mute={false}
                                aspectRatio={aspectRatio}
                            ></Video>
                        </GridItem>
                        <GridItem rowSpan={1} colSpan={1} maxW={475}>
                            <Video
                                autoPlay
                                playsInline
                                srcObject={c3Remote}
                                mute={false}
                                aspectRatio={aspectRatio}
                            ></Video>
                        </GridItem>
                    </Grid>
                </Center>
            </Box>
        </>
    );
}
