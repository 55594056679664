import {
    Box,
    Button,
    Flex,
    Link,
    Image,
    Heading,
    Stack,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";

interface HeroComponentPropTypes {
    title: string;
    subtitle: string;
    image: string;
    ctaLink: string;
    ctaText: string;
    [x: string]: any;
}

export default function HeroComponent({
    title,
    subtitle,
    image,
    ctaLink,
    ctaText,
    ...rest
}: HeroComponentPropTypes) {
    return (
        <Flex
            align="center"
            justify={{
                base: "center",
                md: "space-around",
                xl: "space-between",
            }}
            direction={{ base: "column-reverse", md: "row" }}
            flexWrap="nowrap"
            minH="70vh"
            px={8}
            mb={16}
            {...rest}
        >
            <Stack
                spacing={4}
                w={{ base: "80%", md: "40%" }}
                align={["center", "center", "flex-start", "flex-start"]}
            >
                <Heading
                    as="h1"
                    size="xl"
                    fontWeight="bold"
                    color={useColorModeValue("primary.800", "primary.400")}
                    textAlign={["center", "center", "left", "left"]}
                >
                    {title}
                </Heading>
                <Heading
                    as="h2"
                    size="md"
                    color={useColorModeValue("primary.800", "primary.400")}
                    opacity="0.8"
                    fontWeight="normal"
                    lineHeight={1.5}
                    textAlign={["center", "center", "left", "left"]}
                >
                    {subtitle}
                </Heading>
                <Link href={ctaLink}>
                    <Button colorScheme="primary" size="md">
                        {ctaText}
                    </Button>
                </Link>
                <Text
                    fontSize="xs"
                    mt={2}
                    textAlign="center"
                    color={useColorModeValue("primary.800", "primary.500")}
                    opacity="0.6"
                >
                    Free to use.
                </Text>
            </Stack>
            <Box
                w={{ base: "80%", sm: "60%", md: "50%" }}
                mb={{ base: 12, md: 0 }}
            >
                <Image src={image} size="100%" rounded="1rem" shadow="2xl" />
            </Box>
        </Flex>
    );
}
