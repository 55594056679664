import { HStack, Image, Text } from "@chakra-ui/react";
import logoIcon from "./../img/logo512.png";

export default function Logo(props: any) {
    return (
        <HStack {...props}>
            <Image src={logoIcon} h="35px" />
            <Text fontSize="lg" fontWeight="bold">
                TouchBase
            </Text>
        </HStack>
    );
}
