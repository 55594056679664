import { FcGoogle } from "react-icons/fc";
import { Button, Center, Text } from "@chakra-ui/react";

interface GoogleButtonPropTypes {
    handler: () => void;
}

export default function GoogleButton({ handler }: GoogleButtonPropTypes) {
    return (
        <Center p={8}>
            <Button
                onClick={handler}
                w={"full"}
                maxW={"md"}
                variant={"outline"}
                leftIcon={<FcGoogle />}
            >
                <Center>
                    <Text>Continue with Google</Text>
                </Center>
            </Button>
        </Center>
    );
}
