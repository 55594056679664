import { Box, Center, Text, Stack } from "@chakra-ui/react";
import gCalPic from "./../img/multiple-calendar-accounts@2x.jpg";
import macPic from "./../img/mac.jpg";
import contentBoard from "./../img/1_EPpISNyCRRSBsnanPGg-Ag.jpeg";
import Feature from "../components/Feature";
import ReleaseNote from "../components/ReleaseNote";
import { Helmet } from "react-helmet";
import HashLinkObserver from "react-hash-link";

export default function FeaturesScreen() {
    return (
        <>
            <HashLinkObserver />
            <Helmet>
                <title>Features</title>
            </Helmet>
            <Center flexDir="column" gridGap={10} mb={10} w="100%">
                <Box
                    maxW={1150}
                    w={{ base: "80%", md: "max-content" }}
                    textAlign="center"
                >
                    <Text as="h2" fontSize="4xl">
                        Touch base with the people that matter
                    </Text>
                    <Text>
                        Utilize a secure video platform to maintain your
                        network.
                    </Text>
                </Box>
                <Feature
                    title="Find topics to talk about with ease"
                    subtitle="The customizable layout features an optional feed of content
                based on different searches as food for thought."
                    image={contentBoard}
                    side="left"
                />
                <Feature
                    title="Prioritize security"
                    subtitle="Video calls are solely peer-to-peer, without any server intercepting
            your feed. Devices communicate securely to each other through the
            browser using state-of-the-art WebRTC technology, developed by
            Apple, Google, Microsoft, Mozilla, and Opera."
                    image={macPic}
                    side="right"
                />
                <Feature
                    title="Integrate easily"
                    subtitle="Book your meetings with ease on Google Calendar to continually
            connect with whomever you'd like."
                    image={gCalPic}
                    side="left"
                />
                <Stack spacing={4} maxW={1150} w="80%" id="releases">
                    <Text as="h2" fontSize="3xl" textAlign="center">
                        Release Notes
                    </Text>
                    <ReleaseNote
                        title="Beta v0.0.2"
                        date="December 2021"
                        notes={[
                            "Keyboard shortcuts enabled for easy video controls",
                            "Design Document added to about page",
                        ]}
                    />
                    <ReleaseNote
                        title="Beta v0.0.1"
                        date="August 2021"
                        notes={[
                            "Added support for multiple users leaving/joining seamlessly",
                            "Integrated Google Calendar signups when creating a room",
                            "Rooms now support copying of links and room codes, verification before closing the tab",
                            "Chat functionality is enabled",
                        ]}
                    />
                    <ReleaseNote
                        title="Beta v0.0.0"
                        date="July 2021"
                        notes={[
                            "Enabled basic video functionality complete with mute/audio buttons",
                            "Reddit feed integration to allow users to look up subreddits and access top posts",
                            "Authentication stack implemented to allow Google sign-in and email/password authentication",
                            "Templates for styling/design of website implemented",
                        ]}
                    />
                    <Text>
                        Please note: these release notes may be incomplete
                        and/or infrequent. Some features that are currently
                        being worked on may also not be present above.
                    </Text>
                </Stack>
            </Center>
        </>
    );
}
